import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
        Pragma: "no-cache",
      },
    };
  }

  static listAccounts(dealer_id) {
    return axios.post(ENV.dealerBaseUrl + "/publicapi/account/list", { dealer_id }, this.requestConfig());
  }

  static createAccount(requestData) {
    return axios.post(ENV.dealerBaseUrl + "/publicapi/account/create", requestData, this.requestConfig());
  }

  static revokeAccount(id) {
    return axios.post(ENV.dealerBaseUrl + "/publicapi/account/revoke", { id }, this.requestConfig());
  }

  static requestLogs(requestData) {
    return axios.post(ENV.dealerBaseUrl + "/publicapi/account/logs", requestData, this.requestConfig());
  }

  static requestApiKey(requestData) {
    return axios.post(ENV.dealerBaseUrl + "/publicapi/account/key", requestData, this.requestConfig());
  }

  static getDealer(id) {
    return axios.post(ENV.dealerBaseUrl + "/dealers/details", { id: id }, this.requestConfig());
  }
}

export default Service;
