import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
        Pragma: "no-cache",
      },
    };
  }

  static getSettings() {
    return axios.get(ENV.systemBase + "/settings/find", this.requestConfig());
  }

  static getVersions() {
    return axios.get(ENV.apiBaseB + "/versions/list", this.requestConfig());
  }

  static getDealers() {
    return axios.post(ENV.dealerBaseUrl + "/dealers/list", { embed: "dealer_location" }, this.requestConfig());
  }

  static updateDealer(requestData) {
    return axios.post(ENV.dealerBaseUrl + "/dealers/update", requestData, this.requestConfig());
  }

  static getLocation(requestData) {
    return axios.post(ENV.dealerBaseUrl + "/locations/details", requestData, this.requestConfig());
  }

  static getUsersByLocationId(dealer_location_id) {
    return axios.post(ENV.systemBase + `/users/list_by_location?embed=group`, { dealer_location_id }, this.requestConfig());
  }

  static getLocationCarGarantConfig(ID) {
    return axios.get(ENV.carGarantBase + "/car-garant/api/configuration/get/" + ID, this.requestConfig());
  }

  static getDealerPrivacyStatus(dealer_id) {
    return axios.post(ENV.dealerBaseUrl + "/agreements/privacy/status", { dealer_id }, this.requestConfig());
  }

  static getDealerDataOfficer(dealer_id) {
    return axios.post(ENV.dealerBaseUrl + "/agreements/data_officer/details", { dealer_id }, this.requestConfig());
  }

  static acceptPrivacyAgreement(dealer_id) {
    return axios.post(ENV.dealerBaseUrl + "/agreements/privacy/accept", { dealer_id }, this.requestConfig());
  }

  static getPendingRequestsCount(requestData) {
    return axios.post(ENV.apiBaseB + "/manufacturers/pending_requests_count", requestData, this.requestConfig());
  }

  static getListOfNotificationElements() {
    return axios.get(ENV.systemBase + "/users/notification_elements/list", this.requestConfig());
  }
}

export default Service;
